import admin from "codedrills_proto/io/codedrills/proto/builder/admin_service_grpc_web_pb";
import loadtest from "codedrills_proto/io/codedrills/proto/user/loadtest_service_grpc_web_pb";
import hire from "codedrills_proto/io/codedrills/proto/user/hiring_service_grpc_web_pb";
import user from "codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb";
import customer from "codedrills_proto/io/codedrills/proto/user/customer_service_grpc_web_pb";
import analytics from "codedrills_proto/io/codedrills/proto/user/analytics_service_grpc_web_pb";
import builder from "codedrills_proto/io/codedrills/proto/builder/content_service_grpc_web_pb";
import "codedrills_proto/io/codedrills/proto/builder/admin_service_pb";
import "codedrills_proto/io/codedrills/proto/user/user_pb";
import "codedrills_proto/io/codedrills/proto/user/loadtest_service_pb";
import "codedrills_proto/io/codedrills/proto/user/customer_service_pb";
import "codedrills_proto/io/codedrills/proto/user/analytics_service_pb";
import "codedrills_proto/io/codedrills/proto/builder/content_service_pb";
import "codedrills_proto/io/codedrills/proto/content/content_pb";
import { ApiCall } from "@/utils/api.js";

var builder_proto = proto.io.codedrills.proto.builder;
var user_proto = proto.io.codedrills.proto.user;
var customer_proto = proto.io.codedrills.proto.user;
var content_proto = proto.io.codedrills.proto.content;

const adminService = new admin.AdminServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);
const hiringService = new hire.HiringServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
const userService = new user.UserServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
const loadtestService = new loadtest.LoadtestServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
const customerService = new customer.CustomerServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);
const builderService = new builder.ContentServicePromiseClient(
  process.env.VUE_APP_BUILDER_API_URL + "/builder",
  null,
  null
);
const analyticsService = new analytics.AnalyticsServicePromiseClient(
  process.env.VUE_APP_USER_API_URL + "/user",
  null,
  null
);

const state = {
  user: null,
  adminUsersInfo: [],
  getUsersRequestStatus: 0,
  setUsersRequestStatus: 0,
  updateProblemSolvedStatus: 0, //remove once all registred users subscribed to mail
  digUserStatus: 0,
  createCustomerStatus: 0,
  newCustomerView: null,
  addAdminStatus: 0,
  feedback: [],
  candidateFeedbackStatus: 0,
  gsUrl: 0,
  regenerateReportStatus: 0,
  getCustomerStatus: 0,
  getCustomersSearchStatus: 0,
};

const actions = {
  getUsers: new ApiCall("getUsersRequest")
    .authRequired()
    .withServiceCall((r, h) => adminService.getUsersAdmin(r, h))
    .withRequest(({ email, userId }) => {
      var req = new builder_proto.GetUsersAdminRequest();
      if (email)
        req.setUserAddressesList([
          new user_proto.UserAddress().setEmail(email),
        ]);
      else if (userId)
        req.setUserAddressesList([new user_proto.UserAddress().setId(userId)]);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("User .....", res);
      commit("setUser", res.getUsersList()[0]);
      return res;
    })
    .build(),

  uploadMcqs: new ApiCall("uploadMcqs")
    .authRequired()
    .withServiceCall((r, h) => builderService.uploadMcqCsv(r, h))
    .withRequest(({ mcqs }) => {
      var req = new builder_proto.UploadMcqCsvRequest();
      req.setMcqCsv(mcqs);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Uploading done .....", res);
      return res;
    })
    .build(),

  setUsers: new ApiCall("setUsersRequest")
    .authRequired()
    .withServiceCall((r, h) => adminService.setUsers(r, h))
    .withRequest(() => {
      return new builder_proto.SetUsersRequest().setUsersList([state.user]);
    })
    .onSuccess(({ commit }, res) => commit("setUser", res.getUsersList()[0]))
    .build(),

  createCustomer: new ApiCall("createCustomer")
    .authRequired()
    .withServiceCall((r, h) => customerService.createCustomer(r, h))
    .withRequest(({ name }, __, { commit }) => {
      commit("setNewCustomerView", null);
      return new user_proto.CreateCustomerRequest().setName(name);
    })
    .onSuccess(({ commit }, res) =>
      commit("setNewCustomerView", res.getCustomerView())
    )
    .build(),

  getCustomer: new ApiCall("getCustomer")
    .authRequired()
    .withServiceCall((r, h) => customerService.getCustomer(r, h))
    .withRequest(({ id }) => {
      return new user_proto.GetCustomerRequest().setCustomerId(id);
    })
    .onSuccess(({ commit }, res) => {
      console.log("Customer is  .....", res);
      return res;
    })
    .build(),

  listAdmins: new ApiCall("listAdmins")
    .authRequired()
    .withServiceCall((r, h) => customerService.listAdmins(r, h))
    .withRequest(({ id }) => {
      return new user_proto.ListAdminsRequest().setCustomerId(id);
    })
    .onSuccess(({ commit }, res) => {
      console.log("Customer is  .....", res);
      return res;
    })
    .build(),

  addAdmin: new ApiCall("addAdmin")
    .authRequired()
    .withServiceCall((r, h) => customerService.addAdmin(r, h))
    .withRequest(({ customerId, name, email }, __, { commit }) => {
      commit("setNewCustomerView", null);
      return new user_proto.AddAdminUserRequest()
        .setCustomerId(parseInt(customerId))
        .setAdminBasicProfileList([
          new user_proto.AdminBasicProfile().setEmail(email).setName(name),
        ]);
    })
    //.onSuccess(({ commit }, res) => commit("setNewCustomerView", res.getCustomerView()))
    .build(),

  getCustomersSearch: new ApiCall("getCustomersSearch")
    .authRequired()
    .withServiceCall((r, h) => customerService.getCustomerAdmin(r, h))
    .withRequest(({ name, email, domain, customerId }) => {
      var req = new user_proto.GetCustomerAdminRequest();
      if (name) req.setName(name);
      if (email) req.setEmail(email);
      if (domain) req.setDomain(domain);
      if (customerId) req.setCustomerId(customerId);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Customer is  .....", res);
      return res;
    })
    .build(),

  //remove once all registred users subscribed to mail
  updateProblemSolved: new ApiCall("updateProblemSolved")
    .authRequired()
    .withServiceCall((r, h) => userService.updateProblemSolved(r, h))
    .withRequest(() => {
      return new user_proto.Empty();
    })
    .onSuccess(() => console.log("Done"))
    .build(),

  digUsers: new ApiCall("digUser")
    .authRequired()
    .withServiceCall((r, h) => adminService.digUser(r, h))
    .withRequest(({ emails, getVerificationLink }) => {
      return new builder_proto.DigUserRequest()
        .setUserAddressList(
          emails.map((email) => new user_proto.UserAddress().setEmail(email))
        )
        .setGetVerificationLink(getVerificationLink);
    })
    .onSuccess(({ commit }, res) =>
      commit("setAdminUsers", res.getAdminUserInfoList())
    )
    .build(),

  createUsers: new ApiCall("createUsers")
    .authRequired()
    .withServiceCall((r, h) => loadtestService.createUsers(r, h))
    .withRequest(({ emails }) => {
      return new user_proto.CreateUsersRequest().setEmailList([emails.trim()]);
    })
    .onSuccess(({ commit }, res) => console.log("Users ...", res))
    .build(),

  updatePlanType: new ApiCall("updatePlanType")
    .authRequired()
    .withServiceCall((r, h) => customerService.updateCustomerPlan(r, h))
    .withRequest(({ customerId, type, addOn }) => {
      var req = new customer_proto.UpdateCustomerPlanRequest()
        .setCustomerId(customerId)
        .setHiringPlanType(user_proto.HiringPlanType[type]);
      if (addOn)
        req.setAddOns(
          new user_proto.AddOns().setAddOnList([user_proto.AddOn[addOn]])
        );
      return req;
    })
    .onSuccess(({ commit }, res) => console.log("Plan update done ...", res))
    .build(),

  candidateFeedback: new ApiCall("candidateFeedback")
    .authRequired()
    .withServiceCall((r, h) => analyticsService.fetchAllUserFeedbacks(r, h))
    .withRequest(({ customerId, type }) => {
      return new customer_proto.FetchAllUserFeedbacksRequest();
    })
    .onSuccess(({ commit }, res) =>
      commit("setFeedback", res.getFeedbackInfoList())
    )
    .build(),

  createCoupons: new ApiCall("createCoupons")
    .authRequired()
    .withServiceCall((r, h) => adminService.createPromotionalCoupons(r, h))
    .withRequest(({ coupon, count }) => {
      return new builder_proto.CreatePromotionalCouponsRequest()
        .setCoupon(coupon)
        .setCount(count);
    })
    .onSuccess(({ commit }, res) => commit("setGsUrl", res.getUrl()))
    .build(),

  regenerateReport: new ApiCall("regenerateReport")
    .authRequired()
    .withServiceCall((r, h) => hiringService.regenerateTestReport(r, h))
    .withRequest(({ testId }) => {
      return new user_proto.RegenerateTestReportRequest().setTestId(testId);
    })
    .onSuccess(({ commit }, res) => console.log("Regenerated Report", res))
    .build(),

  getCustomerUsage: new ApiCall("getCustomerUsage")
    .authRequired()
    .withServiceCall((r, h) => customerService.getCustomerUsage(r, h))
    .withRequest(({ customerId, from, to, excludeDomains }) => {
      return new user_proto.GetCustomerUsageRequest()
        .setCustomerId(customerId)
        .setStartTime(from)
        .setEndTime(to)
        .setDomainsToExcludeList(excludeDomains);
    })
    .onSuccess(({ commit }, res) => {
      console.log("Customer usage is  .....", res);
      return res;
    })
    .build(),

  exportProblems: new ApiCall("exportProblems")
    .authRequired()
    .withServiceCall((r, h) => adminService.exportProblems(r, h))
    .withRequest(({ urls }) => {
      var req = new builder_proto.ExportProblemsRequest();
      var contentIdList = [];
      for (var i = 0; i < urls.length; i++) {
        var contentId = new content_proto.ContentId();
        var contentAddress = new content_proto.ContentAddress();
        contentAddress.setUrl(urls[i].trim());
        contentAddress.setContentType(content_proto.ContentType.PROBLEM);
        contentId.setContentAddress(contentAddress);
        contentIdList.push(contentId);
      }
      req.setContentIdsList(contentIdList);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Problems exported .....", res);
      return res;
    })
    .build(),

  exportBulkAdmin: new ApiCall("exportBulkAdmin")
    .authRequired()
    .withServiceCall((r, h) => adminService.startExportProblem(r, h))
    .withRequest(({ companyName }) => {
      var req = new builder_proto.StartExportContentRequest();
      req.setExportName(companyName);
      return req;
    })
    .onSuccess(({ commit }, res) => {
      console.log("Problems exported .....", res);
      return res;
    })
    .build(),
};

const getters = {
  roleKeys() {
    var retRole = {};
    for (var key in user_proto.Role) {
      retRole[user_proto.Role[key]] = key;
    }
    return retRole;
  },
  roleTypeKeys() {
    return Object.keys(user_proto.Role);
  },
  planTypeKeys() {
    return Object.keys(user_proto.HiringPlanType);
  },
  addOnKeys() {
    return Object.keys(user_proto.AddOn);
  },
  scopeKeys() {
    var retScope = {};
    for (var key in user_proto.Scope) {
      retScope[user_proto.Scope[key]] = key;
    }
    return retScope;
  },
  scopeTypeKeys() {
    return Object.keys(user_proto.Scope);
  },
  contentTypeKeys() {
    return Object.keys(content_proto.ContentType);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  getUsersRequestStatus(state, status) {
    state.getUsersRequestStatus = status;
  },
  setUsersRequestStatus(state, status) {
    state.setUsersRequestStatus = status;
  },
  setScopes(state, scopes) {
    if (!state.user.getScopes()) state.user.setScopes(new user_proto.Scopes());
    var scopesEnum = [];
    scopes.map((s) => scopesEnum.push(user_proto.Scope[s]));
    state.user.getScopes().setScopesList(scopesEnum);
    console.log(state.user.getScopes());
  },
  setRole(state, role) {
    console.log("role", role);
    state.user.setRole(user_proto.Role[role]);
  },
  updateProblemSolvedStatus(state, status) {
    //remove once all registred users subscribed to mail
    state.updateProblemSolvedStatus = status;
  },
  createCustomerStatus(state, status) {
    state.createCustomerStatus = status;
  },
  addAdminStatus(state, status) {
    state.addAdminStatus = status;
  },
  setAdminUsers(state, users) {
    state.adminUsersInfo = users;
  },
  digUserStatus(state, status) {
    state.digUserStatus = status;
  },
  setNewCustomerView(state, ncv) {
    state.newCustomerView = ncv;
  },
  setFeedback(state, feedback) {
    state.feedback = feedback;
  },
  candidateFeedbackStatus(state, status) {
    state.candidateFeedbackStatus = status;
  },
  setGsUrl(state, url) {
    state.gsUrl = url;
  },
  regenerateReportStatus(state, status) {
    state.regenerateReportStatus = status;
  },
  getCustomerStatus(state, status) {
    state.getCustomerStatus = status;
  },
  getCustomersSearchStatus(state, status) {
    state.getCustomersSearchStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
